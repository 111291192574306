import React from 'react'
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import InputField from 'components/final-form/InputField';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Space from 'components/Space';
import Select from 'components/final-form/Select';
import { useSelector } from 'react-redux';
import { required } from 'components/final-form/validation';
import SlugStatus from './SlugStatus';

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const SchoolForm = ({
  handleSubmit,
  hideAdminInfo,
  values
}) => {
  const {
    countries,
    cities,
    nationalities,
  } = useSelector(state => state.schools)
  console.log(values)
  return (
    <form onSubmit={handleSubmit}>
        {values.id && (
          <SlugStatus id={values.id} />
        )}
      <Space size={20} />
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Field
            component={InputField}
            label="School name in English"
            name="school_attributes.name_en"
            validate={required}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={InputField}
            label="School name in Arabic"
            name="school_attributes.name_ar"
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Slug"
            name="school_attributes.slug"
            validate={required}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            component={InputField}
            label="Address"
            name="school_attributes.address"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Mobile"
            name="school_attributes.mobile"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Phone"
            name="school_attributes.phone"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Email"
            name="school_attributes.email"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Website"
            name="school_attributes.website"
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={Select}
            label="Country"
            name="school_attributes.country_id"
            options={countries}
            labelKey="name"
            valueKey="id"
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={Select}
            label="City"
            name="school_attributes.city_id"
            options={cities}
            labelKey="name"
            valueKey="id"
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={InputField}
            label="Allowed locations"
            type="number"
            name="school_attributes.allowed_locations"
            // options={cities}
            // labelKey="name"
            // valueKey="id"
            // validate={required}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <Field
            component={Select}
            label="School type"
            name="school_attributes.school_type"
            options={[
              {
                name: 'مدرسة',
                id: 1
              },
              {
                name: 'حضانة',
                id: 2
              },
            ]}
            labelKey="name"
            valueKey="id"
            validate={required}
          />
        </Grid> */}
      </Grid>

      {!hideAdminInfo && (
        <>
          <Space size={40} />
          <Typography variant="h5">
            Admin information
          </Typography>
          <Space size={20} />

          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Field
                component={InputField}
                label="email"
                name="school_admin_attributes.email"
                validate={required}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={InputField}
                label="Mobile"
                name="school_admin_attributes.mobile"
                validate={required}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={Select}
                label="City"
                name="school_admin_attributes.city_id"
                options={cities}
                labelKey="name"
                valueKey="id"
                validate={required}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={Select}
                label="Nationality"
                name="school_admin_attributes.nationality_id"
                options={nationalities}
                labelKey="name"
                valueKey="id"
                validate={required}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Space size={40} />
      <DialogActions>
        <Button autoFocus type="submit" color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
  )
}

export default SchoolForm
